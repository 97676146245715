//Migrated
<template lang="pug">
LayoutWrapper
  template(#header)
    Header
  template(#sidebar)
    Sidebar
  template(#banner)
    Banner
  template(#hero)
    Hero
  template(#content)
    slot
  template(#footer)
    NuxtLazyHydrate(:when-visible="{ rootMargin: '50px' }")
      LazyFooter
</template>

<script setup>
import { BANNER_HERO_IMAGE_BREAKPOINTS } from '@layers/web/constants/banner'

const rootStore = useRootStore()
const {
  pageBannerBackground: bannerBackground,
  pageBannerType: bannerType,
} = storeToRefs(rootStore)

const { t } = useI18n()
const { getLocaleMessages: localeMessages } = useLocaleStore()

useHead(() => {
  const shouldPreloadHero = bannerBackground.value?.image && (
    !bannerBackground.value?.image?.type ||
    bannerBackground.value?.image?.type === 'cloudinary'
  )
  const preloadHeroImageBreakpoints = BANNER_HERO_IMAGE_BREAKPOINTS[bannerType] || BANNER_HERO_IMAGE_BREAKPOINTS.default

  return {
    titleTemplate: `%s - ${t('siteTitleBrand')}`,
    link: [
      // { rel: 'icon', type: 'image/x-icon', href: () => t('favicon') },

      // Preload hero image
      ...(
        !shouldPreloadHero
          ? []
          : createCloudinaryImagePreloadObjects(
            removeImageType(bannerBackground.value.image.url),
            Object.entries(preloadHeroImageBreakpoints).map(
              ([breakpoint, { width, height }]) => ({
                containerWidth: width,
                containerHeight: height,
                screenWidth: parseFloat(breakpoint),
              })
            ), {
              gravity: bannerBackground.value.gravity,
              fetchPriority: 'high',
            }
          )
      ),
    ],

    script: [{
      innerHTML: () => localeMessages.organizationData,
      type: 'application/ld+json',
    }],

    meta: () => [
      { hid: 'author', name: 'author', content: t('siteTitleBrand') },
      {
        hid: 'og:title',
        property: 'og:title',
        content: () => t('siteTitleBrand'),
      },
      {
        hid: 'og:site_name',
        property: 'og:site_name',
        content: () => t('siteTitleBrand'),
      },
    ],
  }
})
</script>
